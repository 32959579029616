import React from 'react';

import { SignInForm } from
  'plaza-react-components/src/components/redesign';

export default function LoginPage({ config, query }) {
  return (
    <SignInForm
      redirectUrl={query.url}
      authUrl={config.AUTH_URL}
      signupUrl={config.SIGNUP_URL}
      passwordResetUrl={config.PASSWORD_RESET_URL}
      retrieveUsernameUrl={config.RETRIEVE_USERNAME_URL}
      {...(query.show_notification && { showNotification: true })}
    />
  );
}
